<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="warning" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.mainInformation') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="name" :label="$t('auth.name')" :rules="rules.name" outlined rounded hide-details="auto" class="pb-3" />
					<v-text-field v-model="website" :label="$t('companies.website')" :rules="rules.website" outlined rounded hide-details="auto" class="pb-3" />
					<v-select
						v-model="employees"
						:label="$t('companies.employees')"
						:items="availableEmployeeOptions"
						item-text="name"
						item-value="key"
						:rules="rules.employees"
						return-object
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
					/>
					<v-text-field
						v-model="instagram"
						:label="$t('social.instagram')"
						:counter="instagramMaxLength"
						:rules="rules.instagram"
						prepend-icon="mdi-instagram"
						prefix="https://instagram.com/"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						placeholder=" "
					/>
					<v-text-field
						v-model="facebook"
						:label="$t('social.facebook')"
						:counter="facebookMaxLength"
						:rules="rules.facebook"
						prepend-icon="mdi-facebook"
						prefix="https://facebook.com/"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						placeholder=" "
					/>
					<v-text-field
						v-model="twitter"
						:label="$t('social.twitter')"
						:counter="twitterMaxLength"
						:rules="rules.twitter"
						prepend-icon="mdi-twitter"
						prefix="https://twitter.com/"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						placeholder=" "
					/>
					<v-text-field
						v-model="linkedin"
						:label="$t('social.linkedin')"
						:counter="linkedinMaxLength"
						:rules="rules.linkedin"
						prepend-icon="mdi-linkedin"
						prefix="https://linkedin.com/"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						placeholder=" "
					/>
					<v-text-field
						v-model="youtube"
						:label="$t('social.youtube')"
						:counter="youtubeMaxLength"
						:rules="rules.youtube"
						prepend-icon="mdi-youtube"
						prefix="https://youtube.com/"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						placeholder=" "
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callUpdate()">
					{{ $t('settings.edit') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyInfoUpdateForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			name: '',
			website: '',
			employees: null,
			instagram: '',
			facebook: '',
			twitter: '',
			linkedin: '',
			youtube: '',
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				website: [
					(v) => (0 <= v.length && (v.startsWith('http://') || v.startsWith('https://'))) || this.$t('rules.website'),
					(v) => 0 < v.length <= this.websiteMaxLength || this.$t('rules.length', { length: this.websiteMaxLength })
				],
				employees: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableEmployeeOptions.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				instagram: [(v) => 0 < v.length <= this.instagramMaxLength || this.$t('rules.length', { length: this.instagramMaxLength })],
				facebook: [(v) => 0 < v.length <= this.facebookMaxLength || this.$t('rules.length', { length: this.facebookMaxLength })],
				twitter: [(v) => 0 < v.length <= this.twitterMaxLength || this.$t('rules.length', { length: this.twitterMaxLength })],
				linkedin: [(v) => 0 < v.length <= this.linkedinMaxLength || this.$t('rules.length', { length: this.linkedinMaxLength })],
				youtube: [(v) => 0 < v.length <= this.youtubeMaxLength || this.$t('rules.length', { length: this.youtubeMaxLength })]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 255
		},
		websiteMaxLength() {
			return 64
		},
		instagramMaxLength() {
			return 64
		},
		facebookMaxLength() {
			return 64
		},
		twitterMaxLength() {
			return 64
		},
		linkedinMaxLength() {
			return 64
		},
		youtubeMaxLength() {
			return 64
		},
		...mapGetters({
			company: 'companies/company',
			availableEmployeeOptions: 'data/availableEmployeeOptions'
		})
	},
	created() {
		this.name = this.company.name
		this.website = this.company.info.website
		this.employees = this.company.info.employees
		this.instagram = this.company.social.instagram
		this.facebook = this.company.social.facebook
		this.twitter = this.company.social.twitter
		this.linkedin = this.company.social.linkedin
		this.youtube = this.company.social.youtube
		this.fetchAvailableEmployeeOptions().then(() => {
			this.loading = false
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.updateCompanyInfo({
				companyID: this.company.id,
				name: this.name,
				website: this.website,
				employees: this.employees,
				instagram: this.instagram,
				facebook: this.facebook,
				twitter: this.twitter,
				linkedin: this.linkedin,
				youtube: this.youtube
			}).then(() => {
				this.logo = null
				this.loading = false
				this.switchEditMode()
			})
		},
		...mapActions('companies', ['updateCompanyInfo']),
		...mapActions('data', ['fetchAvailableEmployeeOptions'])
	}
}
</script>
